//////////
/// Mixins
//////////

/*@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700,800";*/

body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: "Open Sans", sans-serif;
  color: #424242;
  font-size: 14px;
  line-height: 22px;
  background-color: #fff;
}

//////////
/// Colors
//////////

.at-midnight { color: #1b3043; }
.at-dark { color: #333333; }
.at-blue { color: $at-blue; }
.at-gray { color: #808895; }
.at-lightgray { color: #aaaaaa; }
.at-fire { color: #e76325; }
.at-green { color: #88a32e; }
.at-orange { color: #ed8c3b; }
.at-lime { color: #a9bd38; }
.at-mango { color: #f3a843; }
.at-white { color: #ffffff; }

//////////
/// Buttons
//////////

.btn {
  transition: all 200ms ease-in;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  font-family: "Open Sans", sans-serif;
  border: 1px solid #fff;
  text-transform: uppercase;
}
.btn-primary {
  background-color: $at-blue;
  color: #fff;
}
.btn-primary:hover, .btn-primary:focus {
  color: #fff;
  background-color: #1b3043;
}

.openGallery img {
  cursor: pointer;
}

.cursorHover {
  cursor: pointer;
}

.alert-info {
  background-color: #fbfbfb;
  border-color: #ccc;
  color: #333333;
}
.alert-success {
  background-color: #a9bd38;
  border-color: #87972c;
  color: #fff;
  font-weight: 700;
  font-family: 'Cabin', sans-serif;
  font-size: 1.8rem;
}

//////////
/// Reset
//////////

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
:focus {
  outline: 0 !important;
}
iframe {
  border: none;
  width: 100%;
}
::selection {
  background: #fefac7;
  /* Safari */
  color: #555555;
}
::-moz-selection {
  background: #fefac7;
  /* Firefox */
  color: #555555;
}
strong, b {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}
input,
button,
select,
textarea, label {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
hr {
  background-color: #f5f5f5;
  border: 0;
  height: 1px;
  margin: 22px 0 22px 0;
}
.badge {
  font-weight: normal;
  margin-left: 5px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  background-color: $at-blue;
  padding: 3px 6px;
}
a {
  color: $at-blue;
  -moz-transition: all 200ms ease-in;
  -o-transition: all 200ms ease-in;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}
a:hover,
a:focus {
  color: $at-midnight;
  text-decoration: none;
}
.colored-text {
  color: $at-blue;
}

//////////
/// Headings
//////////

h1, h2, h3, h4, h5, h6 {
  color: #060606;
  font-weight: 200;
  margin: 0;
  font-family: "Raleway", sans-serif;
  text-rendering: optimizeLegibility
}

h1 {
  font-size: 3.2em;
  line-height: 44px;
  margin: 0 0 44px 0;
}

h2 {
  font-size: 2.6em;
  line-height: 42px;
  margin: 0 0 32px 0;
}

h3 {
  font-size: 1.8em;
  letter-spacing: normal;
  line-height: 24px;
  margin-bottom: 33px;
}

//////////
/// Forms
//////////

select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .form-control {
  border: 1px solid #ddd;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  font-size: 1.6rem;
  color: #333333;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  background: #FFF;
  -moz-transition: all 200ms ease-in;
  -o-transition: all 200ms ease-in;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

//////////
/// Form Validation
//////////

.form-control-feedback {
  right: 4px;
}
.help-block {
  color: $at-fire !important;
}
.has-error {
  color: $at-fire;
}
.has-success .form-control {
  border-color: $at-lime;
}
.has-success .form-control-feedback {
    color: $at-lime;
}
.has-error .form-control {
  border-color: $at-fire;
}
.has-error .form-control-feedback {
    color: $at-fire;
}
.has-error .control-label {
  color: $at-fire;
}
.has-success .control-label {
  color: $at-lime;
}
.has-error .input-group-addon {
  color: #333;
  border-color: $at-fire;
  background-color: #eee;
}
.has-success .input-group-addon {
  color: #333;
  border-color: $at-lime;
  background-color: #eee;
}

//////////
/// Dividers
//////////

.divide0 {
  width: 100%;
  height: 0px;
}
.divide2 {
  width: 100%;
  height: 2px;
}
.divide3 {
  width: 100%;
  height: 3px;
}
.divide5 {
  width: 100%;
  height: 5px;
}
.divide10 {
  width: 100%;
  height: 10px;
}
.divide15 {
  width: 100%;
  height: 15px;
}
.divide20 {
  width: 100%;
  height: 20px;
}
.divide25 {
  width: 100%;
  height: 25px;
}
.divide30 {
  width: 100%;
  height: 30px;
}
.divide35 {
  width: 100%;
  height: 35px;
}
.divide40 {
  width: 100%;
  height: 40px;
}
.divide45 {
  width: 100%;
  height: 40px;
}
.divide50 {
  width: 100%;
  height: 50px;
}
.divide55 {
  width: 100%;
  height: 55px;
}
.divide60 {
  width: 100%;
  height: 60px;
}
.divide65 {
  width: 100%;
  height: 65px;
}
.divide70 {
  width: 100%;
  height: 70px;
}
.divide75 {
  width: 100%;
  height: 75px;
}
.divide80 {
  width: 100%;
  height: 80px;
}
.divide85 {
  width: 100%;
  height: 85px;
}

//////////
/// Titles
//////////

.center-title {
  padding-bottom: 40px;
  text-align: center;
  h2 {
    font-family: "Raleway", sans-serif;
    font-weight: 100;
    font-size: 4.6rem;
    color: $at-midnight;
  }
  h3 {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0px;
  }
  p {
    margin: 15px 0;
    color: $at-gray;
    font-size: 1.8rem;
  }
}

.title-section {
  font-weight: 600;
  font-size: 21px;
  margin-bottom: 30px;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}
.title-section .link-arrow {
  padding: 2px 8px;
  background-color: $at-blue;
  color: #fff;
  float: right;
  display: inline-block;
  font-size: 11px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.title-section .link-arrow:after {
  text-shadow: none;
  -webkit-font-smoothing: antialiased;
  font-family: 'fontawesome';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  content: "\f105";
  color: #fff;
  left: 0;
  font-size: 13px;
  margin-left: 10px;
  vertical-align: middle;
  position: relative;
  bottom: 2px;
}
.title-section .link-arrow:hover, .title-section .link-arrow:focus {
  color: #fff;
  background-color: $at-midnight;
}

//////////
/// Top Bar
//////////

.top-bar {
  background-color: #203A45;
}
.top-bar .top-welcome {
  color: #fff;
  font-size: 13px;
}
.top-bar .top-social {
  float: right;
}
.top-bar .top-social a {
  display: block;
  float: left;
  color: #fff;
  width: 32px;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.top-bar .top-social a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.select-option select, .select-option .cs-skin-elastic {
  width: 100%;
  max-width: 100%;
}
.header-logo-bar {
  padding: 27px 0;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.header-logo {
  display: inline-block;
  color: #333;
}
.header-icon {
  color: $at-blue;
  font-size: 24px;
  padding-right: 10px;
  vertical-align: -4px;
}
.header-icon img {
  height: 36px;
}
.header-title {
  font-family: "Cabin", sans-serif;
  font-size: 3rem;
  font-weight: bold;
  vertical-align: bottom;
}

.header-slogan h1 {
  border-left: 1px solid $at-lime;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 16px;
  margin: 0px 0px 0px 15px;
  padding: 0px 0px 0px 15px;
  text-align: left;
  vertical-align: bottom;
}
.contact-info-blocks > div {
  display: inline-block;
  margin: 0;
  padding: 4px 10px;
  border-right: 1px solid #efefef;
  color: #999;
  text-align: left;
  font-size: 1.4rem;
}
.contact-info-blocks > div:last-child {
  border-right: 0;
  padding-right: 0;
}
.contact-info-blocks > div i {
  color: $at-blue;
  margin-right: 5px;
}
.contact-info-blocks > div span {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 15px;
  color: #666;
  font-weight: 700;
}
.search-widget {
  position: relative;
}
.search-widget .btn-submit {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  height: 34px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: $at-blue;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.search-widget-2 {
  position: relative;
}
.search-widget-2 .form-control {
  border: 0px;
  border-bottom: 2px solid #f5f5f5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}
.search-widget-2 .form-control:focus {
  border-color: $at-blue;
}
.search-widget-2 .submit-2 {
  position: absolute;
  top: 5px;
  right: 5px;
}

//////////
/// Nav Bar
//////////

.navbar-inverse {
  background-color: $at-midnight;
  border-color: rgba(0, 0, 0, 0.1);
  width: 100%;
}
.navbar-inverse .navbar-nav > li > a {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
}
.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:focus, .navbar-inverse .navbar-nav > .active > a:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
}
.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
}
.dropdown-menu {
  padding: 0px;
  border: 2px solid #f5f5f5;
  min-width: 180px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  border-top-color: transparent;
}
.dropdown-menu > li > a {
  padding: 7px 20px;
  border-bottom: 1px solid #f3f3f3;
  font-size: 13px;
  color: $at-blue;
}

/* Mega MENU */
.yamm-content {
  padding: 25px;
  box-sizing: border-box;
  background-color: #fff;
}

//////////
/// Destination Map
//////////

#destinationMap_wrapper {
    height: 680px;
}

#destinationMap_canvas {
    width: 100%;
    height: 100%;
}
.destinationMap {
  width: 100%;
  height: 600px;
}

//////////
/// Property / Cabin
//////////

.image {
  position: relative;
  overflow: hidden;
}
.label-sleeps {
  display: inline-block;
  padding: 3px 12px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  left: 0;
}
.label-price {
  display: inline-block;
  padding: 3px 12px;
  color: #000;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  right: 0;
}
.image a {
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  background-color: rgba(0, 0, 0, 0.25);
  display: block;
  opacity: 0;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  text-align: center;
  width: 100%;
  z-index: 200;
}
.image a i {
  width: 100%;
  position: absolute;
  top: 50%;
  color: #fff;
  display: block;
  font-size: 25px;
  margin-top: -40px;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.image:hover a {
  opacity: 1;
}
.image:hover a i {
  margin-top: -10px;
}
/// Properties
.property-detail {
  padding: 12px 0;
  min-height: 71px;
}
.property-detail .title {
  font-weight: 600;
  font-size: 16px;
}
.property-detail .location {
  display: block;
  font-size: 13px;
  color: #777;
  margin: 0px 0;
}
.property-detail .pull-right img {
  margin-left: 10px;
  margin-right: 4px;
  width: 14px !important;
  display: inline-block !important;
}
/// Destinations
.destination-detail {
  padding: 12px 0;
}
.destination-detail .title {
  font-weight: 600;
  font-size: 16px;
}
.destination-detail .location {
  display: block;
  font-size: 13px;
  color: #777;
  margin: 0px 0;
}
.destination-detail .pull-right img {
  margin-left: 10px;
  margin-right: 4px;
  width: 14px !important;
  display: inline-block !important;
}

//////////
/// Footer
//////////

.footer {
  background-color: #212121;
  padding-top: 50px;
  padding-bottom: 30px;
  color: #aeaeae;
}
.footer h3 {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 18px;
  text-transform: capitalize;
  color: #fff;
}
.footer .media-heading {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
}
.footer .media-heading a {
  color: #fff;
}
.footer .media-heading a:hover {
  color: $at-lime;
}
.footer .location {
  font-size: 13px;
}
.footer .location {
  a {
    color: #FFFFFF;
  }
  a:hover { 
    color: $at-lime;
  }
}
.footer ul {
  margin: 0;
  padding: 0;
}
.footer ul li {
  line-height: 24px;
  font-size: 13px;
}
.footer ul li a {
  color: #FFFFFF;
}
.footer ul li a:hover {
  color: $at-lime;
}
.footer p a {
  color: #FFFFFF;
}
.footer p a:hover {
  color: $at-lime;
}
.copyright {
  padding: 10px 0;
  background-color: #333;
  color: #aaa;
}

//////////
/// Call to Action
//////////

.call-to-action {
  padding-top: 40px;
  padding-bottom: 25px;
  background-color: #203A45;
}
.call-to-action h3 {
  color: #fff;
  font-weight: 300;
  text-transform: capitalize;
  margin: 0px;
  line-height: 50px;
  margin-bottom: 15px;
  font-size: 30px;
}
.call-to-action a {
  display: inline-block;
  padding: 10px 25px;
  text-align: center;
  color: #fff;
  border: 1px solid #fff;
  background-color: $at-blue;
}
.call-to-action a:hover, .call-to-action a:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.2);
}

//////////
/// Date Picker
//////////

.datepicker td {
  text-align: center;
  width: 35px;
  height: 35px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover:active {
  background: $at-lime;
}
.datepicker table tr td.highlighted {
  background: #f9f9f9;
}
.datepicker table tr td.selected, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected:hover {
  background: $at-blue;
}
.datepicker table tr td.range, .datepicker table tr td.range.disabled, .datepicker table tr td.range.disabled:hover, .datepicker table tr td.range:hover {
  background: #eee;
}
.datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
  background: rgba( $at-lime, .4 );
}
.datepicker table tr td.active.active, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active:active, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover:active {
  background: $at-lime;
}
.datepicker table tr td.today.active, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled.disabled, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today.disabled:hover.active, .datepicker table tr td.today.disabled:hover.disabled, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today.disabled:hover:hover, .datepicker table tr td.today.disabled:hover[disabled], .datepicker table tr td.today.disabled[disabled], .datepicker table tr td.today:active, .datepicker table tr td.today:hover, .datepicker table tr td.today:hover.active, .datepicker table tr td.today:hover.disabled, .datepicker table tr td.today:hover:active, .datepicker table tr td.today:hover:hover, .datepicker table tr td.today:hover[disabled], .datepicker table tr td.today[disabled] {
  background: $at-lime;
}
.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
  background: rgba( $at-blue, .3 );
  cursor: not-allowed;
}
.datepicker-dropdown {
  background: #FFFFFF !important;
}

//////////
/// Slider Revolution
//////////

.slider-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 100;
  background-color: rgba(27,48,67, 0.8);
  padding: 15px 20px;
  color: #fff;
  font-size: 32px;
}
.slider-text {
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
  padding: 10px 20px;
  font-size: 14px;
}
.slider-cta {
  background-color: $at-blue;
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 700;
}

//////////
/// All Property Slider
//////////

.all-property-slider {
  position: relative;
}
.all-property-slider .owl-buttons {
  position: absolute;
  top: -66px;
  right: 12px;
}
.all-property-slider .owl-theme .owl-controls .owl-buttons div {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  padding: 0px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background-color: $at-blue;
}
.all-property-slider .owl-item {
  padding: 0 15px;
}

//////////
/// Below Banner Page Title
//////////

.bannerBottomTitle {
  padding-top: 40px;
  background-color: #f8f8f8;
  h3 {
    font-weight: 600;
    font-size: 21px;
    display: block;
  }
}


//////////
/// Search Filters
//////////

.search-filter {
  padding-top: 40px;
  padding-bottom: 20px;
  background-color: #f8f8f8;
}
.select-option .cs-skin-elastic .cs-options {
  z-index: 1001;
}
.search-filter a {
  margin-top: 22px;
}

//////////
/// Agents
//////////

.agent-box {
  text-align: center;
}
.agent-box img {
  display: block;
  margin: 0 auto;
}
.agent-box h3 {
  font-weight: 400;
  margin: 15px 0;
  font-size: 20px;
}
.agent-box a {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  padding: 2px 8px;
  background-color: $at-blue;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  margin-top: 10px;
  -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}
.agent-box a:hover, .agent-box a:focus {
  background-color: #333;
  color: #fff;
}

//////////
/// Sidebar
//////////

.sidebar-box {
  margin-bottom: 30px;
}
.sidebar-box h3 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
  background-color: #f5f5f5;
  text-align: center;
  line-height: 35px;
}
.sidebar-box .btn-red i {
  margin-right: 7px;
}
.sidebar-box .media {
  background-color: #eee;
  padding: 10px;
}
.sidebar-box .media-heading {
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 0px;
}
.sidebar-box em {
  font-size: 12px;
  display: block;
  margin-bottom: 0px;
}

//////////
/// Property Listing
//////////

.property-listing {
  margin-bottom: 40px;
}
.property-listing h3 {
  margin-bottom: 5px;
}
.property-listing .btn-default {
  background-color: #f9f9f9;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  margin: 10px 0;
}
.property-listing span {
  margin-right: 20px;
  display: inline-block;
}
.property-listing span img {
  margin-right: 3px;
}

//////////
/// Pagination
//////////

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: $at-blue;
  border-color: $at-blue;
}
.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
  z-index: 3;
  color: #fff;
  background-color: $at-blue;
  border-color: $at-blue;
}
.pagination > li > a, .pagination > li > span {
  color: $at-blue;
}

//////////
/// Thumb Slider
//////////

.thumb-slider {
  margin-bottom: 30px;
}
.thumb-slider .flexslider {
  margin: 0 0 10px;
}
.thumb-slider .flex-direction-nav a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-shadow: none;
  background-color: $at-blue;
  color: #fff;
}
.thumb-slider .flex-direction-nav a.flex-next:before {
  content: "\f105";
  color: #fff;
}
.thumb-slider .flex-direction-nav a:before {
  font-family: "FontAwesome";
  font-size: 40px;
  display: inline-block;
  content: "\f104";
  color: #fff;
}
.thumb-slider #carousel img {
  display: block;
  opacity: .5;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}
.thumb-slider #carousel img:hover {
  opacity: 1;
}
.thumb-slider .flex-active-slide img {
  opacity: 1 !important;
}



.property-meta span {
  display: inline-block;
  padding: 3px 8px;
  background-color: #eee;
  color: #555;
  text-transform: uppercase;
  border-right: 2px solid #ddd;
  margin: 3px;
}

.additional-amenities span {
  padding: 4px 8px;
  background-color: #f8f8f8;
  margin: 4px;
  color: #555;
  display: inline-block;
}

.additional-amenities span.available {
  color: #88a32e;
}

.agent-social a {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 18px;
  color: #333;
  background-color: #f8f8f8;
  margin: 3px;
}
.agent-social a:hover {
  color: #fff;
  background-color: $at-blue;
}

.about-agency h3 {
  margin-bottom: 10px;
}

.agency-details i {
  float: left;
  margin-right: 5px;
  font-size: 15px;
  color: $at-blue;
  width: 40px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  display: block;
}
.agency-details span {
  display: block;
  overflow: hidden;
}

//////////
/// Profile Key Meta
//////////
.profile-meta {
  padding: 14px;
  background-color: #f8f8f8;
}
.profile-meta h3 {
  margin-bottom: 0;
}
// .profile-meta .price {
//   float: right;
// }

//////////
/// Pricing Tables
//////////

.price-box {
  padding: 4px;
  border: 2px solid #ddd;
  text-align: center;
}
.price-box .price-header {
  background-color: #f5f5f5;
  padding: 20px;
}
.price-box .price-header h3 {
  color: $at-blue;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.price-box .price-features {
  padding: 15px 0;
}
.price-box .price-features ul {
  margin: 0;
  padding: 0;
}
.price-box .price-features ul li {
  line-height: 34px;
  border-bottom: 1px solid #f5f5f5;
}
.price-box .price-bottom {
  padding: 15px 0;
  text-align: center;
}

/***faqs****/
.panel-default > .panel-heading {
  position: relative;
}
.panel-title {
  color: $at-blue;
  font-weight: 700;
}

.panel-default > .panel-heading:after {
  content: '\f067';
  color: #333;
  display: block;
  font-family: 'FontAwesome';
  position: absolute;
  right: 15px;
  top: 8px;
}

.panel-default > .panel-heading.active:after {
  content: '\f068';
}

.faq-box {
  padding: 15px;
  border: 1px solid #eee;
  margin-top: 40px;
}
.faq-box h4 {
  font-weight: 400;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.required span {
  color: red;
}

.form-contact ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.form-contact ul li {
  padding: 0px 10px;
  color: #fff;
  background-color: red;
  font-size: 12px;
}

.form-contact p {
  margin-top: 0px;
}

.contact-column h4 {
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 15px;
}

.social-1 li a {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background-color: $at-blue;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: block;
}

.property-submit-form h4 {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
}
.property-submit-form .form-control {
  min-height: 44px;
}
.property-submit-form .image-placeholder {
  background: #eee;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
}
.property-submit-form .checkbox {
  padding-left: 20px;
}

.login-reg-box {
  padding: 15px;
  background-color: #f5f5f5;
}
.login-reg-box h4 {
  margin-bottom: 20px;
}
.login-reg-box .checkbox {
  padding-left: 20px;
}

.social-btn {
  height: 40px;
  padding-right: 20px;
  color: #fff;
  margin-right: 10px;
  font-size: 22px;
  margin-bottom: 10px;
  display: inline-block;
}
.social-btn:hover {
  opacity: 0.8;
  color: #fff;
}
.social-btn i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 20px;
}

.social-btn.facebook {
  background-color: #3b5998;
}

.social-btn.g-plus {
  background-color: #C63D2D;
}

.tweet ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.tweet ul li {
  padding-bottom: 20px;
}
.tweet ul li a {
  color: $at-blue;
}
.tweet ul li a:hover {
  color: $at-blue;
  text-decoration: underline;
}

.tag-list a {
  display: inline-block;
  margin: 3px;
  padding: 3px 7px;
  background-color: #eee;
  color: #333;
}
.tag-list a:hover {
  color: #fff;
  background-color: $at-blue;
}
.tag-list a i {
  margin-right: 3px;
}

/**blog inner pages**/
.blog-post {
  border-bottom: 1px dashed #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 60px;
}

.post-detail {
  margin-top: 5px;
  margin-bottom: 0px;
  border-top: 1px solid #ebeaea;
  border-bottom: 1px solid #ebeaea;
  margin-left: 0px;
}

.post-detail li img {
  width: 26px;
  height: 26px;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  border-radius: 13px;
  float: left;
  margin-right: 10px;
}

.post-detail li + li {
  padding-left: 15px;
}

.post-detail li {
  font-size: 11px;
  color: #777;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
}

.post-detail li i {
  margin-right: 5px;
}

.blog-post h2 {
  font-size: 25px;
  margin-bottom: 10px;
  margin-top: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.blog-left-img .post-detail {
  margin-top: 0px;
}

.blog-post p {
  margin-bottom: 20px;
  font-size: 15px;
}

.comment-form h3 {
  margin-bottom: 15px;
  text-transform: uppercase;
}

.quote {
  position: relative;
}

.quote blockquote {
  padding: 0px;
  border: 0;
  margin: 0;
  font-size: 14px;
  font-style: italic;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}

.quote blockquote p {
  color: #333;
  padding-top: 25px;
  padding-bottom: 45px;
  padding-left: 30px;
  padding-right: 30px;
}

.quote blockquote:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 90px;
  width: 0;
  height: 0;
  border-top: 0.83333em solid #ccc;
  border-right: 0.86667em solid transparent;
}

.quote.lightgray blockquote {
  background-color: #f9f9f9;
}

.quote.lightgray blockquote:before {
  border-top-color: #f9f9f9;
}

.quote.dark blockquote {
  background-color: #555;
}

.quote.dark blockquote:before {
  border-top-color: #555;
}

.quote-footer {
  margin: 10px 0;
}

.quote-footer .quote-author-img img {
  float: left;
  max-width: 90px;
  width: 90px;
  height: 90px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  margin-left: -5px;
  margin-top: -40px;
  position: relative;
  z-index: 1;
  padding: 5px;
  background-color: #fff;
}

.quote-footer h4 {
  font-size: 14px;
  margin-bottom: 4px;
}

.quote-footer p {
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
}

.cabintitle h1 {
  margin: 0;
  padding: 0;
  font-weight: 100;
}
.cabintitle h1 span {
  font-size: 50%;
  color: $at-gray;
}
.cabintitle h2 {
  margin: 0 0 10px 20px;
  font-size: 2.4rem;
  font-weight: 100;
}
.cabintitle h3 {
  margin: 30px 40px 40px;
}

.phoneweb h3 {
  margin: 10px;
  padding: 0;
  font-weight: 700;
}

/******************pricing tables***********************/
/**pricing simple **/
.pricing-simple {
  border: 1px solid #D0D6DF;
  margin-bottom: 30px;
}
.pricing-simple h4 {
  border-bottom: 1px solid #D0D6DF;
  margin: 0px;
  color: $at-midnight;
  padding: 20px;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
}
.pricing-simple h3 {
  margin: 0px;
  padding: 30px 20px;
  border-bottom: 1px solid #D0D6DF;
  font-size: 50px;
  font-weight: 400;
  text-align: center;
  background-color: #f4f4f4;
}
.pricing-simple h3 sup, .pricing-simple h3 sub {
  font-size: 14px;
  color: $at-gray;
  font-weight: 400;
}
.pricing-simple h3 sup {
  vertical-align: top;
  left: 10px;
}
.pricing-simple h3 sub {
  vertical-align: bottom;
}
.pricing-simple h3 span {
  text-decoration: line-through;
  color: $at-lightgray;
}
.pricing-simple ul {
  padding-top: 30px;
}
.pricing-simple ul li {
  padding: 6px 20px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 1.4rem;
  text-align: center;
}
.pricing-simple ul li:last-child {
  border-bottom: 0px;
}
.pricing-simple ul li i {
  color: $at-blue;
  margin-right: 10px;
}
.pricing-simple ul li span {
  color: $at-lime;
  font-size: 3.2rem;
  font-weight: 700;
  display: block;
}
.pricing-simple .bottom {
  padding: 20px 10px;
  text-align: center;
}
.pricing-simple p {
  color: #aeaeae;
  padding: 15px 20px;
  text-align: center;
  padding-bottom: 0px;
}
.pricing-simple .circle {
  width: 150px;
  height: 150px;
  padding: 0px 0 0;
  margin: 30px auto;
  margin-bottom: 0px;
  display: table;
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  -ms-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  border: 5px solid #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #f4f4f4;
}
.pricing-simple .circle .price {
  text-align: center;
  font-size: 30px;
  display: table-cell;
  vertical-align: middle;
}
.pricing-simple .circle .price sub, .pricing-simple .circle .price sup {
  font-size: 14px;
  color: #777;
}
.pricing-simple .circle .price sup {
  vertical-align: top;
}
.pricing-simple .circle .price sub {
  vertical-align: bottom;
}
.popular {
  position: relative;
  overflow: hidden;
}
.popular .ribbon {
  position: absolute;
  left: -55px;
  bottom: 5px;
  font-size: 11px;
  text-align: center;
  width: 150px;
  color: #fff;
  text-transform: capitalize;
  padding: 1px 12px;
  display: block;
  -webkit-box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
  background-color: $at-orange;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.no-space-pricing {
  margin: 0px;
}

/**dark pricing tables**/
.pricing-simple.dark-pricing {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #333;
}
.pricing-simple.dark-pricing h4 {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.pricing-simple.dark-pricing .circle {
  background-color: #444;
  border-color: #333;
}
.pricing-simple.dark-pricing .circle .price {
  color: #fff;
}
.pricing-simple.dark-pricing ul li {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

/****services new*****/
.service-box-4 .service-ico {
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  font-size: 30px;
  line-height: 30px;
  margin-top: 3px;
  float: left;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.service-ico i {
  color: $at-lime;
  font-size: 50px;
}

.service-box-4 .service-text {
  margin-left: 65px;
}

.service-text a {
  color: $at-blue;
}

.service-text a i {
  margin-left: 5px;
  vertical-align: middle;
}

.service-text h4 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 5px;
}

.wide-img-showcase {
  overflow: hidden;
}

.team-wrap {
  padding: 3px 3px 10px;
  border: 1px solid #eee;
  text-align: center;
}

/*!
 * Yamm!3
 * Yet another megamenu for Bootstrap 3
 * 
 * http://geedmo.github.com/yamm3
 */
.yamm .nav,
.yamm .collapse,
.yamm .dropup,
.yamm .yamm-fw {
  position: static;
}

.yamm .container {
  position: relative;
}

.yamm .dropdown.yamm-fw .dropdown-menu {
  left: 15px;
  right: 15px;
}

.tp-caption a:hover {
  color: lighten($at-lime, 20%);
}

.affix {
  top: 1px;
}

//////////
/// FlexSlider - Profile Images
//////////

.flexslider {
margin: 0 0 60px;
background: #fff;
border: 4px solid #fff;
position: relative;
-webkit-border-radius: 4px;
-moz-border-radius: 4px;
-o-border-radius: 4px;
border-radius: 4px;
-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
-moz-box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
-o-box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
zoom: 1;
}
#slider .slides li {
  position: relative;
}
#slider .slides .imageTitle {
  display: inline-block;
  padding: 3px 12px;
  color: #000;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  left: 20px;
}
#slider .slides img{
  width:  auto !important;
  margin: 0 auto !important;
}
#carousel .slides img {
  object-fit: cover;
  width: 80px;
  height: 80px;
}

//////////
/// Drop Down Main Menu
//////////

.dropdown-menu {
  min-width: 200px;
  background: #2851a3;
}
.dropdown-menu.columns-2 {
  min-width: 360px;
}
.dropdown-menu.columns-3 {
  min-width: 600px;
}
.dropdown-menu li a {
  padding: 5px 15px;
  font-weight: 300;
}
.multi-column-dropdown {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.multi-column-dropdown li a {
  display: block;
  clear: both;
  line-height: 1.4;
  white-space: normal;
  color: #FFF;
  font-weight: 400;
}
.multi-column-dropdown li a:hover {
  text-decoration: none;
  background-color: #274b91;
}
 
@media (max-width: 767px) {
  .dropdown-menu.multi-column {
    min-width: 240px !important;
    overflow-x: hidden;
  }
}

//////////
/// Margins and Padding
//////////

.mt10 {margin-top: 10px;}
.mt20 {margin-top: 20px;}
.mt30 {margin-top: 30px;}
.mt40 {margin-top: 40px;}
.mt50 {margin-top: 50px;}
.margin10 {margin-bottom: 10px;}
.margin20 {margin-bottom: 20px;}
.margin30 {margin-bottom: 30px;}
.margin40 {margin-bottom: 40px;}

.no-padding {padding: 0px;}

.m-x-auto {margin-right: auto !important;margin-left: auto !important;}
.m-a-0 {margin: 0 0 !important;}
.m-t-0 {margin-top: 0 !important;}
.m-r-0 {margin-right: 0 !important;}
.m-b-0 {margin-bottom: 0 !important;}
.m-l-0 {margin-left: 0 !important;}
.m-x-0 {margin-right: 0 !important;margin-left: 0 !important;}
.m-y-0 {margin-top: 0 !important;margin-bottom: 0 !important;}
.m-a-1 {margin: 1rem 1rem !important;}
.m-t-1 {margin-top: 1rem !important;}
.m-r-1 {margin-right: 1rem !important;}
.m-b-1 {margin-bottom: 1rem !important;}
.m-l-1 {margin-left: 1rem !important;}
.m-x-1 {margin-right: 1rem !important;margin-left: 1rem !important;}
.m-y-1 {margin-top: 1rem !important;margin-bottom: 1rem !important;}
.m-a-2 {margin: 1.5rem 1.5rem !important;}
.m-t-2 {margin-top: 1.5rem !important;}
.m-r-2 {margin-right: 1.5rem !important;}
.m-b-2 {margin-bottom: 1.5rem !important;}
.m-l-2 {margin-left: 1.5rem !important;}
.m-x-2 {margin-right: 1.5rem !important;margin-left: 1.5rem !important;}
.m-y-2 {margin-top: 1.5rem !important;margin-bottom: 1.5rem !important;}
.m-a-3 {margin: 3rem 3rem !important;}
.m-t-25 {margin-top: 2.5rem !important;}
.m-t-3 {margin-top: 3rem !important;}
.m-r-3 {margin-right: 3rem !important;}
.m-b-3 {margin-bottom: 3rem !important;}
.m-l-3 {margin-left: 3rem !important;}
.m-x-3 {margin-right: 3rem !important;margin-left: 3rem !important;}
.m-y-3 {margin-top: 3rem !important;margin-bottom: 3rem !important;}
.p-a-0 {padding: 0 0 !important;}
.p-t-0 {padding-top: 0 !important;}
.p-r-0 {padding-right: 0 !important;}
.p-b-0 {padding-bottom: 0 !important;}
.p-l-0 {padding-left: 0 !important;}
.p-x-0 {padding-right: 0 !important;padding-left: 0 !important;}
.p-y-0 {padding-top: 0 !important;padding-bottom: 0 !important;}
.p-a-1 {padding: 1rem 1rem !important;}
.p-t-1 {padding-top: 1rem !important;}
.p-r-1 {padding-right: 1rem !important;}
.p-b-1 {padding-bottom: 1rem !important;}
.p-l-1 {padding-left: 1rem !important;}
.p-x-1 {padding-right: 1rem !important;padding-left: 1rem !important;}
.p-y-1 {padding-top: 1rem !important;padding-bottom: 1rem !important;}
.p-a-2 {padding: 1.5rem 1.5rem !important;}
.p-t-2 {padding-top: 1.5rem !important;}
.p-r-2 {padding-right: 1.5rem !important;}
.p-b-2 {padding-bottom: 1.5rem !important;}
.p-l-2 {padding-left: 1.5rem !important;}
.p-x-2 {padding-right: 1.5rem !important;padding-left: 1.5rem !important;}
.p-y-2 {padding-top: 1.5rem !important;padding-bottom: 1.5rem !important;}
.p-a-3 {padding: 3rem 3rem !important;}
.p-t-3 {padding-top: 3rem !important;}
.p-r-3 {padding-right: 3rem !important;}
.p-b-3 {padding-bottom: 3rem !important;}
.p-l-3 {padding-left: 3rem !important;}
.p-x-3 {padding-right: 3rem !important;padding-left: 3rem !important;}
.p-y-3 {padding-top: 3rem !important;padding-bottom: 3rem !important;}