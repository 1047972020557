@charset "UTF-8";

// Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:100,400,500,700,900);
@import url(https://fonts.googleapis.com/css?family=Cabin:100,400,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,600);

// Pe-icon-7-stroke
// http://themes-pixeden.com/font-demos/7-stroke/
@import "../../../resources/assets/vendors/pe-icons/Pe-icon-7-stroke.css";

// Flat Icon
// https://www.flaticon.com/packs/hand-drawn-hotel
@import "../../../resources/assets/vendors/flaticon/flaticon.css";

// Variables
@import "variables";

// Bootstrap
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../../node_modules/font-awesome/css/font-awesome.css";
@import "../../../node_modules/flexslider/flexslider.css";
@import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.min.css";
@import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.min.css";
@import "../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
@import "../../../node_modules/formvalidation/dist/css/formValidation.min.css";
@import "../../../node_modules/ekko-lightbox/dist/ekko-lightbox.css";
@import "../../../resources/assets/vendors/rs-plugin/css/settings.css";
@import "../../../resources/assets/css/rev-style.css";

// Cutom
@import "custom";

// Media
@import "media";
