/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
	.search-widget {
    margin-top: 20px;
  }
  .navbar-right {
    float: right !important;
    margin-right: 0px;
  }
  .navbar-inverse .navbar-toggle {
    border-color: rgba(255, 255, 255, 0.4);
  }
  .navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
    background-color: #333;
    border-color: rgba(0, 0, 0, 0.4);
  }
  .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
    border-color: rgba(0, 0, 0, 0.2);
  }
	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #eee;
  }
  .dropdown-menu > li > a {
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }
	.additional-amenities {
		margin-bottom: 60px;
	}
	.call-to-action h3 {
		font-size: 20px;
		line-height: 24px;
	}
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width: 480px) {

  // Global
	h1 {
		line-height: 3.2rem;
		font-size: 3rem;
	}
	h2 {
		line-height: 2.8rem;
		font-size: 2.6rem;
	}
	h3 {
		line-height: 2rem;
		font-size: 1.8rem;
	}
	.center-title {
		padding-bottom: 20px;
	}
	.center-title h2 {
		line-height: 2.8rem;
		font-size: 2.6rem;
	}
	.center-title h3 {
		font-size: 1.8rem;
	}
	.center-title p {
		font-size: 1.4rem;
	}

	// Banner Images
	.caption {
	  line-height: 80px;
	}
	.slider-title {
	  font-size: 75px;
		top: 200px
	}

	// Destination Map
	.destinationMap {
	  width: 100%;
	  height: 300px;
	}
	.info-box {
		height: 240px;
	}
	.info-box h4 {
		margin: 0;
		padding: 0;
		font-size: 14px;
		line-height: 15px;
	}
	.info-box p {
		font-size: 8px;
		line-height: 9px;
	}

	// Profile Page
	.cabintitle h1 span {
		display: block;
		margin-left: 10px;
	}
	.cabintitle h3 {
		margin: 20px 16px 26px;
	}
	#slider .slides .imageTitle {
		left: 0;
	}
	.flex-direction-nav {
    display: none;
  }
	.inquiryForm h3 small {
		display: block;
	}
	.profile-meta:last-child {
		margin-top: 10px;
	}

}

/* Custom, iPhone Retina */ 
@media only screen and (max-width: 320px) {

}