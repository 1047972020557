
// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #2851a3;
$brand-info: #aaaaaa;
$brand-success: #a9bd38;
$brand-warning: #f3a843;
$brand-danger: #e76325;

/* AllTrips Brand
## Colors used in the AllTrips brand.*/
$at-midnight: #1b3043;
$at-dark: #203A45;
$at-blue: #2851a3;
$at-gray: #808895;
$at-lightgray: #aaaaaa;
$at-fire: #e76325;
$at-green: #88a32e;
$at-orange: #ed8c3b;
$at-lime: #a9bd38;
$at-mango: #f3a843;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($at-lime, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
